<template>
  <b-card
    title=" "
    aria-hidden="true"
    class="mb-0"
  >
    <b-form @submit.prevent="$emit('processCurso', curso)">
      <!-- Sistema Info: Input Fields -->
      <b-form>
        <b-row>

          <!-- Field: TipoEnseñanza -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Tipo Enseñanza"
              label-for="tipoEnseñanza"
            >
              <v-select
                v-model="curso.idNivel"
                placeholder="Selecciona el tipo enseñanza..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="tipoEnseñanzasOptions"
                :disabled="true"
                :state="!$v.curso.idNivel.$invalid"
                @input="changeTipoEnsenanza"
              />
              <b-form-invalid-feedback
                v-if="$v.curso.$dirty"
                id="idNivelInfo"
              >
                El campo tipo enseñanza
                <span v-if="!$v.curso.idNivel.required">
                  es requerido.
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col> -->
          <!-- Field: Grados -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Grado"
              label-for="grado"
            >
              <v-select
                v-model="curso.idGrado"
                placeholder="Selecciona el grado..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="gradosOptions"
                :disabled="true"
                :state="!$v.curso.idGrado.$invalid"
                @input="$v.curso.$touch"
              />
              <b-form-invalid-feedback
                v-if="$v.curso.$dirty"
                id="gradoInfo"
              >
                El campo grado
                <span v-if="!$v.curso.idGrado.required">
                  es requerido.
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col> -->

          <!-- Field: letra -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Letra"
              label-for="letra"
            >
              <b-form-input
                id="letra"
                v-model="curso.letra"
                placeholder="Ingresa la letra"
                :state="curso.letra
                  ? $v.curso.letra.$invalid == true
                    ? false
                    : null
                  : null"
                @input="$v.curso.$touch"
              />
              <b-form-invalid-feedback
                v-if="$v.curso.$dirty"
                id="letraInfo"
              >
                El campo letra
                <span v-if="!$v.curso.letra.required">
                  es requerido.
                </span>
              </b-form-invalid-feedback>

            </b-form-group>
          </b-col>

        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <btnSubmit
        variant="primary"
        :btnText="cursoSubmit"
        @processBtn="submitOption"
      />

    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BForm, BFormInvalidFeedback, BFormInput,
  BCard,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

import btnSubmit from '../../components/Form/btnSubmit.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInvalidFeedback,
    BFormInput,
    BCard,

    // vSelect,
    btnSubmit,
  },
  mixins: [validationMixin],
  props: {
    curso: {
      type: Object,
      required: true,
    },
    cursoSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Sistema',
    },
  },
  data() {
    return {
      dir: 'ltr',
      establecimientosOptions: [],
      // periodosOptions: [],
      tipoEnseñanzasOptions: [],
      gradosOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      getEstablecimientosActivos: 'establecimientos/getEstablecimientosActivos',
      // getPeriodos: 'periodos/getPeriodos',
      getTipoEnseñanzas: 'tipoEnseñanza/getTipoEnseñanzas',
      getGrados: 'grados/getGrados',
      // getDocentesActivos: 'usuarios/getDocentesActivos',
    }),
    // disabledPeriodo() {
    //   return this.periodosOptions.length
    // },
    disabledTipoEnseñanza() {
      return this.curso.idEstablecimiento.length
    },
    disabledGrado() {
      return this.gradosOptions.length
    },
  },
  validations: {
    curso: {
      letra: {
        required,
      },
      // idNivel: {
      //   // required,
      // },
      // idGrado: {
      //   required,
      // },
    },
  },
  watch: {
    getEstablecimientosActivos() {
      this.setEstablecimientosActivosOptions(this.getEstablecimientosActivos)
    },
    getTipoEnseñanzas() {
      this.setTipoEnseñanzasOptions(this.getTipoEnseñanzas)
    },
  },
  mounted() {
    this.fetchEstablecimientosActivos()
    this.fetchTipoEnseñanza()
  },
  methods: {
    ...mapActions({
      fetchEstablecimientosActivos: 'establecimientos/fetchEstablecimientosActivos',
      // fetchPeriodos: 'periodos/fetchPeriodos',
      fetchTipoEnseñanza: 'tipoEnseñanza/fetchTipoEnseñanza',
      fetchGrados: 'grados/fetchGrados',
      // fetchDocentesActivos: 'usuarios/fetchDocentesActivos',
    }),
    submitOption() {
      this.$v.curso.$touch()
      if (!this.$v.curso.$invalid) {
        this.$emit('processCurso', this.curso)
      }
    },
    // changeEstablecimiento() {
    //   // this.$v.curso.$touch
    //   this.resetAll()
    //   const idEstablecimiento = this.curso.idEstablecimiento.id
    //   // const { idPeriodoActivo } = this.getEstablecimientosActivos.find(ge => ge.id === idEstablecimiento)

    //   // this.resetDocentesOptions()
    //   // this.fetchDocentesActivos().then(() => {
    //   //   this.setDocentesOptions(this.getDocentesActivos, idEstablecimiento)
    //   // })
    //   // hacer fetch a periodos y luego hacer set
    // },
    // setPeriodosOptions(periodos, idPeriodoActivo) {
    //   periodos.forEach(periodo => {
    //     const title = periodo.id === idPeriodoActivo
    //       ? `${periodo.nombre} - Activo`
    //       : periodo.nombre
    //     this.periodosOptions.push({
    //       id: periodo.id,
    //       title,
    //     })
    //   })
    // },
    // setDocentesOptions(docentes, idEstablecimientoSeleccionado) {
    //   this.resetDocentesOptions()
    //   docentes.forEach(docente => {
    //     const {
    //       id,
    //       idEstablecimiento,
    //       rut,
    //       nombres,
    //       primerApellido,
    //       segundoApellido,
    //     } = docente
    //     if (idEstablecimiento === idEstablecimientoSeleccionado) {
    //       const title = `${rut} - ${nombres}
    //         ${primerApellido} ${segundoApellido}`

    //       this.docentesOptions.push({
    //         id,
    //         title,
    //       })
    //     }
    //   })
    // },
    setEstablecimientosActivosOptions(establecimientosActivos) {
      // solo si es super admin y admin daem muestra todos los establecimientos
      establecimientosActivos.forEach(establecimientoActivo => {
        this.establecimientosOptions.push({
          id: establecimientoActivo.id,
          title: establecimientoActivo.nombre,
          insignia: establecimientoActivo.insignia,
        })
      })
    },
    setTipoEnseñanzasOptions(tipoEnseñanzas) {
      this.tipoEnseñanzasOptions = []
      tipoEnseñanzas.forEach(tipoEnseñanza => {
        const title = `${tipoEnseñanza.idNivel} - ${tipoEnseñanza.nombre}`
        this.tipoEnseñanzasOptions.push({
          id: tipoEnseñanza.id,
          idNivel: tipoEnseñanza.idNivel,
          title,
        })
      })
    },
    changeTipoEnsenanza() {
      this.resetGradosOptions()
      const { idNivel } = this.curso.idNivel
      this.fetchGrados().then(() => {
        const grados = this.getGrados.filter(gg => gg.idNivel === idNivel)
        this.setGradosOptions(grados)
      })
    },
    setGradosOptions(grados) {
      this.resetGradosOptions()
      grados.forEach(grado => {
        const title = `${grado.nombre}`
        this.gradosOptions.push({
          id: grado.id,
          idGrado: grado.idGrado,
          title,
        })
      })
    },
    resetGradosOptions() {
      this.gradosOptions = []
    },
    resetAll() {
      this.curso.idGrado = null
      this.curso.idNivel = null
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
